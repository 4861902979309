<template>
  <div>
    <div
      v-html="
        blockContent.replace(/https:\/\/parkos/g, `https://${$paths.urlEnvPrefix}parkos`)
      "
    />
    <ul v-if="slug === 'footer_about'">
      <li
        v-if="aboutPageLink"
        id="dynamic-placeholder"
        class="text-white text-[14px] xs:text-base"
        @click="
          sendEvent('about_us_Click', 'Action Tracking', 'Hom-Remove-links-at-the-header')
        "
      >
        <a :href="aboutPageLink">
          <div
            class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"
          >
            {{ aboutPageTitle }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getCookie, nginxDecodeUserId } from '~/helpers/cookieHelpers';

export default {
  props: {
    slug: undefined,
  },

  computed: {
    blockContent() {
      if (!this.template?.page_content && this.slug === 'footer_airports') {
        return `
      <ul>\r\n
      <li><strong>${this.$i18n('general.airports')}</strong></li>\r\n
      ${this.airports
    .map(
      (airport) => `<li><a class="text-white hover:text-white" href="/${airport.slug}/">${airport.name}</a></li>\r\n`,
    )
    .slice(0, 5)
    .join('')}
      </ul>
    `;
      }

      if (this.slug === 'footer_airports') {
        return (
          this.template?.page_content.replace(
            '#airports',
            `https://${this.$paths.host}/#airports`,
          ) ?? ''
        );
      }

      return this.template?.page_content ?? '';
    },

    template() {
      return this.$store.state.pageTemplate[this.slug];
    },
    airports() {
      return this.$store.state.airports;
    },
    language() {
      return this.$store.state.language;
    },
    aboutPageLink() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return `/${currentContent.slug}.html`;
      }
      return null;
    },
    aboutPageTitle() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return currentContent.title;
      }
      return null;
    },
    aboutPageContent() {
      return this.$store.state.pageContent.aboutPageContent;
    },
  },
  mounted() {
    setTimeout(() => {
      const customerServiceEl = document.querySelector('[ref="AboutUs"]');
      if (customerServiceEl) {
        customerServiceEl.addEventListener('click', () => this.sendEvent(
          'Customer_Service_Click',
          'Action Tracking',
          'Hom-Remove-links-at-the-header',
        ));
      }
    });
  },
  methods: {
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () => window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie('uid');
      if (!visitorIdCookie) {
        console.log('[FLAGSHIP GTM] VisitorId is not defined');
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie('xpe');
      const flagshipEventsAPIEndpoint = this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: 'APP',
            ev: 1,
            t: 'EVENT',
            ea,
            ec,
            el,
          }),
        },
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`,
        );
      }
    },
  },
};
</script>

<style scoped>
.text-white a,
.text-white a:hover {
  color: inherit;
}

.strong {
  font-weight: bolder;
}
</style>
